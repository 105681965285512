<template>
  <header
    class="fixed py-[22px] xl:pt-[29px] xl:pb-[31px] inset-x-0 z-[9999] hidden w-full lg:block transition-all duration-150 h-[68px] xl:h-[88px]"
    :class="navClasses"
  >
    <div
      class="container flex items-center justify-between gap-4 transition-all"
      :class="[isActiveHeader ? '' : ' is-not-scroll']"
    >
      <nav class="flex items-center justify-between text-black">
        <!-- Logo -->
        <nuxt-link
          :to="$i18nPath('/')"
          aria-label="Benelli Logo"
          class="transition-all ltr:pr-[58px] ltr:pl-[2px] rtl:pr-[2px] rtl:pl-[58px]"
          :prefetch="false"
        >
          <Logo
            :color="
              state.scrollPosition >= 80 ||
              (state.isStorePage && !state.isProductPage)
                ? '#000000'
                : 'white'
            "
            class="transition-all h-[20px] lg:h-[24px] xl:h-[28px] w-[90px] lg:w-[106px] xl:w-[124px]"
          />
        </nuxt-link>

        <!-- Models -->
        <LazyFlyoutMenu
          v-if="products.length > 0"
          is-product
          class="mt-0.5"
          :link-title="$t('common.models')"
        />

        <!-- <FlyoutMenu v-if="categories.length > 0">
          <a
            class="uppercase cursor-pointer nav-link hover-effect"
            :class="[
              state.isProductPage ? 'nav-product' : '',
              dynamicLinkClass,
              newTextClass,
            ]"
            v-text="$t('common.accessories')"
          />
        </FlyoutMenu> -->

        <span
          v-if="products.length > 0"
          class="mx-6 h-6 w-[3px] bg-primary inline-block"
        ></span>

        <div class="flex items-center space-s-7 mt-0.5">
          <!-- Stores -->
          <!-- v-if="store.enableShop" -->
          <nuxt-link
            :to="$i18nPath('/stores')"
            class="uppercase nav-link hover-effect"
            :class="[dynamicLinkClass, newTextClass]"
            :prefetch="false"
          >
            {{ $t("common.find_a_store") }}
          </nuxt-link>

          <!-- News -->
          <nuxt-link
            v-if="currentSite && currentSite.news"
            :to="$i18nPath('/news')"
            class="uppercase nav-link hover-effect"
            :class="[dynamicLinkClass, newTextClass]"
            :prefetch="false"
          >
            {{ $t("common.news") }}
          </nuxt-link>
          <!-- About us -->
          <nuxt-link
            :to="$i18nPath('/about-us')"
            class="uppercase nav-link hover-effect"
            :class="[dynamicLinkClass, newTextClass]"
            :prefetch="false"
          >
            {{ $t("common.about_us") }}
          </nuxt-link>
          <!-- Shop online -->
          <nuxt-link
            v-if="store.enableShop"
            :to="$i18nPath('/search')"
            class="uppercase nav-link hover-effect"
            :class="[dynamicLinkClass, newTextClass]"
            :prefetch="false"
          >
            {{ $t("common.shop_online") }}
          </nuxt-link>

          <nuxt-link
            v-if="
              currentSiteSettings &&
              currentSiteSettings.become_a_partner &&
              store.country != 'int'
            "
            :to="$i18nPath('/become-a-dealer')"
            class="uppercase nav-link hover-effect"
            :class="[dynamicLinkClass, newTextClass]"
            :prefetch="false"
          >
            {{ $t("common.become_a_dealer") }}
          </nuxt-link>
          <nuxt-link
            v-if="
              currentSiteSettings &&
              currentSiteSettings.become_a_partner &&
              store.country === 'int'
            "
            :to="$i18nPath('/become-a-distributor')"
            class="uppercase nav-link hover-effect"
            :class="[dynamicLinkClass, newTextClass]"
            :prefetch="false"
          >
            {{ $t("common.become_a_distributor") }}
          </nuxt-link>
          <!-- Contact us -->
          <a
            v-if="shopLink"
            :href="shopLink"
            class="uppercase nav-link hover-effect"
            :class="[dynamicLinkClass, newTextClass]"
            target="_"
            :prefetch="false"
          >
            {{ $t("common.shop") }}
          </a>
          <a
            v-else-if="store.country === 'ar'"
            href="https://benellitestride.benelliargentina.com.ar/"
            class="uppercase nav-link hover-effect"
            :class="[dynamicLinkClass, newTextClass]"
            target="_"
            :prefetch="false"
          >
            {{ $t("common.test_ride") }}
          </a>
          <a
            v-if="store.country === 'mx'"
            href="https://www.elektra.mx/benelli"
            class="uppercase nav-link hover-effect"
            :class="[dynamicLinkClass, newTextClass]"
            target="_"
            :prefetch="false"
          >
            Cotizar
          </a>
          <nuxt-link
            v-if="store.country === 'it'"
            :to="$i18nPath('/contact-us')"
            class="uppercase nav-link hover-effect"
            :class="[dynamicLinkClass, newTextClass]"
            :prefetch="false"
          >
            {{ $t("common.contact_us") }}
          </nuxt-link>
        </div>
      </nav>
      <nav>
        <LazyLocaleSelector
          :color="color"
          :name="state.isProductPage ? 'headerproduct' : 'header'"
        />
        <!-- User selector -->
        <LazyUserDropdown v-if="store.enableShop" :color="color" />
      </nav>
    </div>
  </header>
</template>
<script setup>
import { useMainStore } from "@/store/index";

const nuxtApp = useNuxtApp();
const store = useMainStore();
const route = useRoute();
const router = useRouter();

const $i18nPath = nuxtApp.$i18nPath;
const color = ref("white");
const state = reactive({
  isStorePage: false,
  isProductPage: false,
  isAboutUsPage: false,
  scrollPosition: 0,
});

state.isStorePage = !!(route.name == "country-stores");
state.isAboutUsPage = !!(route.name == "country-about-us");

const isActiveHeader = computed(() => {
  return state.scrollPosition >= 80 || state.isStorePage;
});

const navClasses = computed(() => {
  const classes = [];
  /* } else if (removeLastDash($i18nPath($route.path)) === removeLastDash($i18nPath('/'))) {
    classes.push('bg-opacity-20')
  } */
  if (route.path.startsWith("/products/")) {
    classes.push("md:mb-[-110px] bg-opacity-0");
  }
  if (
    state.scrollPosition >= 80 &&
    (state.isProductPage || state.isAboutUsPage)
  ) {
    classes.push("bg-white scrollable-header ");
  } else if (isActiveHeader.value) {
    classes.push("bg-white scrollable-header scrollable-header-shadow");
  } else {
    classes.push("bg-gradient-to-b	from-[#00000054] to-[#0000000f]");
  }
  return classes;
});

const newTextClass = computed(() => {
  if (store.locale === "el" || store.locale === "ar") {
    return "xl:text-xs text-[11px] ";
  } else {
    return "xl:text-sm text-[11px] ";
  }
});

const shopLink = computed(() => {
  switch (store.country) {
    case "hu":
      return "http://www.benelliaruhaz.hu";

    // case 'es':
    //   return 'https://spaziobenelli.blog/';

    case "gb":
      return "https://www.motogb.co.uk/shop/";

    case "it":
      return "http://www.benellishop.it";

    default:
      return false;
  }
});
const dynamicLinkClass = computed(() => {
  return isActiveHeader.value
    ? "text-black hover:text-black"
    : "text-white hover:text-white";
});

const products = computed(() => {
  return store.products;
});
const categories = store.categories;
const currentSite = store.currentSite;
const currentSiteSettings = store.currentSiteSettings;

// watch(
//   router.currentRoute,
//   (newValue, oldValue) => {
//     if (newValue.name == "country-products-slug") {
//       state.isProductPage = true;
//     } else {
//       state.isProductPage = false;
//     }
//     if (newValue.name == "country-stores") {
//       state.isStorePage = true;
//     } else {
//       state.isStorePage = false;
//     }
//     if (newValue.name == "country-about-us") {
//       state.isAboutUsPage = true;
//     } else {
//       state.isAboutUsPage = false;
//     }
//   },
//   { deep: true }
// );

onBeforeMount(() => {
  if (!process.browser) {
    return;
  }
  state.isProductPage = window.location.href.includes("/products/");
});

onMounted(() => {
  if (!process.browser) {
    return;
  }
  if (import.meta.client) {
    nuxtApp.hook("page:loading:end", () => {
      if (router.currentRoute.value.name == "country-products-slug") {
        state.isProductPage = true;
      } else {
        state.isProductPage = false;
      }
      if (router.currentRoute.value.name == "country-stores") {
        state.isStorePage = true;
      } else {
        state.isStorePage = false;
      }
      if (router.currentRoute.value.name == "country-about-us") {
        state.isAboutUsPage = true;
      } else {
        state.isAboutUsPage = false;
      }
    });
  }
  window.addEventListener("scroll", updateScroll);
});

onBeforeUnmount(() => {
  window.removeEventListener("scroll", updateScroll);
});

function updateScroll() {
  state.scrollPosition = window.scrollY;
}

function changeColor(color) {
  color.value = color;
}
</script>

<style lang="postcss">
.nav-link {
  @apply block z-10 italic xl:text-lg lg:text-base text-sm hover:text-primary font-myriad-pro-condensed font-normal hover:opacity-100 hover:no-underline focus:no-underline focus:outline-none relative;
  /* &.hover-effect::before {
    transform: scaleX(1);
  } */
  &.nav-product {
    @apply text-white hover:text-white;
    /* &.hover-effect::before {
      @apply bg-[#5A5A5A] !important;
    } */
  }
  &.nuxt-link-exact-active {
    @apply text-primary;
  }
}
.is-not-scroll {
  /* .nav-link.hover-effect::before {
    @apply bg-white;
  } */
}
.scrollable-header {
  @apply bg-white;
}
.scrollable-header-shadow {
  box-shadow: 0px 2px 2px 0px rgba(151, 151, 153, 0.12);
}
</style>
